import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { breakpoints } from "../styles/breakpoints";

const StyledContainer = styled.div`
    max-width: 1110px;
    padding: 0 30px;
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    margin: 0 auto;
    /* top: 0; */
    @media (min-width: ${breakpoints.small}) {
        top: 40px;
    }
`

const fontSize = ".8125rem";

const StyledButton = styled(props => <Link {...props} />)`
  color: var(--sand-med);
  text-decoration: none;
  font-size: ${fontSize};
  text-transform: uppercase;
  svg {
      fill: var(--sand-med);
      padding-right: 10px;
      width: 13px;
  }
  span {
        display: inline!important;
        font-size: ${fontSize}!important;
    }
`;

const BackButton = (props) => {
    return (
        <StyledContainer>
            <StyledButton className="back" to={`/#${props.hash}`}>
                <svg viewBox="0 0 11.748 11.748"><g><path data-name="Line 3" d="M.734 5.139h10.28a.734.734 0 0 1 .734.735.734.734 0 0 1-.734.734H.734A.734.734 0 0 1 0 5.874a.734.734 0 0 1 .734-.735Z"/><path data-name="Path 20" d="M5.874 0a.734.734 0 0 1 .519 1.253L1.772 5.874l4.621 4.621a.734.734 0 0 1-1.038 1.038l-5.14-5.14a.734.734 0 0 1 0-1.038l5.14-5.14A.732.732 0 0 1 5.874 0Z"/></g></svg>
                Back<span className='mobile-hide'> to Portfolio</span>
            </StyledButton>
        </StyledContainer>
    )
}
export default BackButton;